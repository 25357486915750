import React from 'react';
import {Spin} from 'antd';
import {
  withAuthUser,
  withAuthUserTokenSSR,
  AuthAction
} from 'next-firebase-auth'

function IndexPage() {
  return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <style>{`
          html,
          body,
          body > div:first-child,
          div#__next,
          div#__next > div {
            height: 100%;
          }
        `}</style>
        <Spin />
      </div>
  )
}
// Note that this is a higher-order function.
export const getServerSideProps = withAuthUserTokenSSR({
  whenUnauthed: AuthAction.SHOW_LOADER,
  whenAuthed: AuthAction.SHOW_LOADER,
})()

export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(IndexPage)

